@import "lib";

body.error-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    margin: 0;

    main {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        padding: 0;

        #content {
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .alert-container {
        text-align: center;
        background-color: $white;
        padding: 4rem 7rem;

        h1 {
            font-size: 2rem;
        }

        p {
            text-transform: uppercase;
            font-size: 1rem;

            img {
                margin-bottom: 2rem;
            }

            a {
                text-decoration: underline;
                color: inherit;
            }
        }

        @include media-breakpoint-down(xs) {
            padding: 1rem;
        }
    }

    &.error-500-page {
        #header .header-content {
            justify-content: center;

            .header-row {
                #left-header, #right-header, .header-toggler {
                    display: none !important;
                }
            }
        }

        .error-illustration {
            display: none;
        }
    }

    #navbar-collapsed .js-login-menu {
        display: none;
    }
}
